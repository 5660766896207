import React, { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import Logo from "../../images/logo.png";
import "./Header.css";
import CartIcon from "../../images/cart.svg";
import CartContext from "../../pages/cart/CartContext";
const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { cartItems } = useContext(CartContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        color="#FF8900"
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, my: 2 }}
      >
        <img src={Logo} alt="Sri Caterers" height={"70"} width="200" />
      </Typography>
      <Divider />
      <ul className="mobile-navigation">
        <li>
          <NavLink activeClassName="active" to={"/"}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to={"/menu"}>Menu</NavLink>
        </li>
        <li>
          <NavLink to={"/about"}>About us</NavLink>
        </li>
      </ul>
    </Box>
  );
  return (
    <>
      <Box>
        <AppBar component={"nav"} sx={{ bgcolor: "white" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                mr: 2,
                display: { sm: "none" },
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              color="#FF8900"
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
              <img src={Logo} alt="Sri Caterers" height={"70"} width="250" />
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ul className="navigation-menu">
                <li>
                  <NavLink activeClassName="active" to={"/"}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to={"/menu"}>Menu</NavLink>
                </li>
                <li>
                  <NavLink to={"/about"}>About us</NavLink>
                </li>
                <NavItem>
                  <NavLink to="/cart">
                    <p>Cart</p>
                    <NavCartItem>
                      <img src={CartIcon} alt="Shopping cart" />
                      {/* If the number of cartItems is greater than 0, display the
                    number of items in the cart */}
                      {cartItems.length > 0 && (
                        <CartCircle>{cartItems.length}</CartCircle>
                      )}
                    </NavCartItem>
                  </NavLink>
                </NavItem>
              </ul>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "240px",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box>
          <Toolbar />
        </Box>
      </Box>
    </>
  );
};

const NavCartItem = styled.div`
  position: relative;

  img {
    @media (max-width: 500px) {
      display: none;
    }
  }
`;

const NavItem = styled.li`
  margin: 0 1.25em;

  a,
  span {
    color: #13122e;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      padding: 1rem;
      border-bottom: 1px solid #cfcfd0;
      max-height: 45px;

      &:hover {
        background-color: #cfcfd0;
      }
    }

    img {
      width: 1.6rem;
    }
  }
  p {
    display: none;
  }

  @media (max-width: 500px) {
    width: 100%;

    &:nth-of-type(4) a {
      display: flex;
      justify-content: space-between !important;
      align-items: center;
      border: none;
    }

    p {
      display: initial;
    }
  }
`;
const CartCircle = styled.div`
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #ff8900;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85em;

  @media (max-width: 500px) {
    position: initial;
  }
`;

export default Header;
