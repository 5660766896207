import React from "react";
import Layout from "./../components/Layout/Layout";
import { Box, Typography } from "@mui/material";

import ChefHands from "../images/chef_hands.png";

const About = () => {
  return (
    <Layout>
      <Box
        sx={{
          m: 5,
          textAlign: "center",
          p: 2,
          "& h4": {
            fontWeight: "bold",
            my: 2,
            fontSize: "2rem",
          },
          "& p": {
            textAlign: "center",
          },
          "@media (max-width:600px)": {
            mt: 0,
            "& h4 ": {
              fontSize: "1.5rem",
            },
          },
        }}
      >
        <Typography variant="h4">Welcome to Sri Cateres</Typography>
        <img src={ChefHands} alt="Sri Caterers" height={"400"} width="400" />
        <Typography variant="h6" m={4}>
          If you're looking for outstanding caterers, you've come to the right
          place. At Select Caterers, we pride ourselves on providing exceptional
          catering services for all occasions. We have a wide range of menu
          options to choose from, and our team of experienced and certified
          chefs will work with you to create the perfect meal for your event.
          We're dedicated to providing outstanding customer service, and we'll
          work with you to ensure that your event is a success. Contact us today
          to learn more about our catering services.
        </Typography>
        <br />
      </Box>
    </Layout>
  );
};

export default About;
