import React from "react";
import Header from "./Header";
import StickyFooter from "../../pages/StickyFooter";
const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div>{children}</div>
      <StickyFooter />
    </>
  );
};

export default Layout;
