import React from "react";
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import HomeIcon from "@mui/icons-material/Home";

const Contact = () => {
  return (
    <Container component="main" maxWidth="sm">
      <Paper
        elevation={3}
        sx={{ padding: 2, mt: 2, mb: 2, borderRadius: "16px" }}
      >
        <Box mt={2}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon color="warning" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="h6">Email</Typography>}
                    secondary={
                      <Typography variant="body1">
                        manisanagarapu@gmail.com
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon color="warning" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="h6">Phone</Typography>}
                    secondary={
                      <Typography variant="body1">
                        (+91) 79930 80863 / 99859 69182
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <HomeIcon color="warning" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="h6">Address</Typography>}
                    secondary={
                      <Typography variant="body1">
                        Kuragallu, Amaravathi Region, Mangalagiri Mandal,
                        Guntur, Andhrapradesh - 522503
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default Contact;
