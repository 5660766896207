import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "../components/Layout/Card";
import { MenuList } from "../data/data";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import OverlayCard from "../components/Layout/OverlayCard";

const SpecialItems = ({ initialSlide = 0 }) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    horizontal: true,
    horizontalSwiping: true,
    swipeToSlide: true,
    waitForAnimate: true,
    useTransform: false,
    arrows: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  const [hasSetPosition, setHasSetPosition] = useState(false);
  const slider = useRef(null);

  useEffect(() => {
    if (slider.current && !hasSetPosition) {
      slider.current.slickGoTo(initialSlide);
      setHasSetPosition(true);
    }
  }, [initialSlide, hasSetPosition, slider]);

  return (
    <div>
      {/* <div className="controls">
        <button onClick={slider?.current?.slickPrev()}>
          <FaChevronLeft />
        </button>
        <button onClick={slider?.current?.slickNext()}>
          <FaChevronRight />
        </button>
      </div> */}
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {MenuList.map((item, index) => (
          // <ItemCard item={item} />
          <Card key={index} item={item} currentSlide={index} />
        ))}
      </div>
    </div>
  );
};
export default SpecialItems;
