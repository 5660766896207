import React from "react";
import { ParallaxBanner } from "react-scroll-parallax";
import { Button, Grid, Typography, Paper } from "@mui/material";
import { Link } from "react-router-dom";

import Banner from "../images/bannerhome.png";
import SpecialItems from "./SpecialItems.js";
import Layout from "./../components/Layout/Layout";
import "../styles/HomeStyles.css";
import Contact from "./Contact";
import TestimonialSlider from "./TestimonialSlider";
import SectionHeader from "../components/SectionHeader";
import StickyFooter from "./StickyFooter";

const Home = () => {
  return (
    <Layout>
      <ParallaxBanner
        layers={[
          {
            image: Banner,
            speed: 20,
            height: 200,
          },
        ]}
        style={{ aspectRatio: "2 / 1", height: 500, width: "100%" }}
      >
        <div
          className="home"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="text"
            size="large"
            sx={{
              textTransform: "capitalize",
              color: "#fff",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Helvitika",
                fontStyle: "italic",
                fontSize: 50,
              }}
            >
              Serving Happiness, One Adorable Bite at a Time
            </Typography>
          </Button>
          <Link to="/menu" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
              }}
              style={{
                background: "#ff0000",
                color: "#fff",
              }}
            >
              Book your plate
            </Button>
          </Link>
        </div>
      </ParallaxBanner>
      {/*       <Paper>
        <div
          className="home"
          style={{ backgroundImage: `url(${Banner})`, height: "600px" }}
        >
          <Grid item>
            <Typography
              variant="h4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                padding: "20px",
                fontSize: "60px",
                fontFamily: "cursive",
              }}
            >
              We are the Best
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link to="/menu" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                style={{
                  background: "#ff0000",
                  width: "200px",
                  color: "#fff",
                }}
              >
                Book your plate
              </Button>
            </Link>
          </Grid>
        </div>
      </Paper> */}
      <SectionHeader name={"Our Special Items"} />
      <SpecialItems />
      <SectionHeader name={"Testimonials"} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TestimonialSlider />
      </div>
      <SectionHeader name={"Contact Us"} />
      <Contact />
    </Layout>
  );
};

export default Home;
