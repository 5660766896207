import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/TestimonialStyle.css";

const TestimonialSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [testimonials, setTestimonials] = useState([
    {
      name: "Sudha",
      company: "Mangalagiri",
      quote:
        "Recently Hosted a dinner party for 250 members. Initially worried about food and service. though lot of positive reviews, but you people made absolutely fantastic food with very good service. keep it up. I would definitely recommend to others. thank you",
    },
    {
      name: "Prasad",
      company: "Guntur",
      quote: `It was a great experience of the food taste and quality u maintained in my wedding, not only food also the hygienic maintained for you was outstanding. We are so glad to select you as our caters.Hope in upcoming events also we will definitely choose you service.

All the very best for Catering service from our entire Family 🙂`,
    },
    {
      name: "Jaya lakhsmi",
      company: "Vijayawada",
      quote:
        "Excellent food quality, ordered them for 3 events in our apartment, well maintained unique taste, really impressed with your food and service. Highly recommended.",
    },
  ]);
  const nextSlide = () => {
    setActiveIndex((currentActiveIndex) => {
      const nextIndex = (currentActiveIndex + 1) % testimonials.length;
      return nextIndex;
    });
  };
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    horizontal: true,
    horizontalSwiping: true,
    swipeToSlide: true,
    waitForAnimate: true,
    useTransform: false,
    arrows: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  return (
    <div
      style={{
        marginLeft: 100,
        marginRight: 100,
        width: "50%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Slider
        dots={false}
        arrows={true}
        infinite={true}
        slidesPerRow={1}
        speed={500}
        autoplay={true}
        afterChange={(currentActiveIndex) => setActiveIndex(currentActiveIndex)}
        {...settings}
      >
        {testimonials.map((testimonial, index) => (
          <div className="testimonial-slider">
            <div className="testimonial">
              <h2>{testimonial.name}</h2>
              <p>{testimonial.quote}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TestimonialSlider;
