import React from "react";

const SectionHeader = ({ name }) => {
  return (
    <div
      className="seven"
      style={{
        position: "relative",
        width: "90%",
        maxWidth: "500px",
        padding: "1em",
        margin: "1.5em auto",
      }}
    >
      <h1>{name}</h1>
    </div>
  );
};

export default SectionHeader;
