import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Layout from "../../components/Layout/Layout";
import CartContext from "./CartContext";
import ChefEmptyPlate from "../../images/chef-emptyplate.png";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";

const CartPage = () => {
  const { cartItems, checkout, clearCart, removeFromCart } =
    useContext(CartContext);

  const [numPlates, setNumPlates] = useState(1);
  const [coupon, setCoupon] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [customerName, setCustomerName] = useState("");
  const [customerMobile, setCustomerMobile] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const availableCoupons = [
    { code: "DISCOUNT10", discount: 0.1 },
    { code: "DISCOUNT20", discount: 0.2 },
  ];

  useEffect(() => {
    const cost =
      cartItems.reduce((sum, item) => sum + Number(item.item_price), 0) *
      numPlates;

    console.log("CART ITEMS", cost);
    const discount =
      availableCoupons.find((c) => c.code === coupon)?.discount || 0;
    setTotalCost(cost - cost * discount);
  }, [cartItems, numPlates, coupon]);

  const handleDelete = (id) => {
    removeFromCart(cartItems.filter((item) => item.id !== id));
  };

  const validateMobileNumber = (number) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(number);
  };
  const navigate = useNavigate();

  const handleCheckout = async () => {
    let hasErrors = false;
    const errors = {};

    if (!customerName) {
      errors.customerName = "Name is required";
      hasErrors = true;
    }

    if (!validateMobileNumber(customerMobile)) {
      errors.customerMobile = "Invalid mobile number";
      hasErrors = true;
    }

    setErrors(errors);

    if (!hasErrors) {
      const orderDetails = {
        customerName,
        customerMobile,
        cartItems,
        numPlates,
        totalCost,
      };

      console.log("ITEMS", orderDetails);
      try {
        // Sending order details to the PHP endpoint
        const response = await axios.post(
          "https://www.sricaterers.com/order.php",
          orderDetails
        );

        // Handle the response
        if (response.status === 200) {
          setSuccessMessage(response.data.message);
          // Optionally, you can reset the form or redirect to a success page
          setCustomerName("");
          setCustomerMobile("");
          // setCartItems([]);
          cartItems?.forEach((element) => {
            removeFromCart(element);
          });
          setNumPlates(1);
          setCoupon("");
        } else {
          // Handle error scenarios if needed
          console.error("Failed to place order:", response.data.message);
        }
      } catch (error) {
        console.error("Error placing order:", error);
      }
    }
  };

  const handleCouponChange = (event) => {
    setCoupon(event.target.value);
  };

  return (
    <Layout>
      <Container maxWidth="md">
        {cartItems.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            textAlign="center"
          >
            <img
              src={ChefEmptyPlate}
              alt="Empty Cart"
              style={{ width: "150px", height: "150px", marginBottom: "20px" }}
            />
            <Typography variant="h6" gutterBottom>
              Your Plate is empty.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Looks like you haven't added anything to your plate yet.
            </Typography>
            <Button
              variant="contained"
              color="warning"
              sx={{ mt: 2, px: 4 }}
              onClick={() => {
                navigate("/menu");
              }}
            >
              Browse Menu
            </Button>
          </Box>
        ) : (
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              mt: 4,
              mb: 4,
              height: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: 4,
            }}
          >
            <Typography variant="h4" gutterBottom color="tomato">
              Your Cart
            </Typography>
            <Grid container spacing={2}>
              {cartItems.map((item) => (
                <Grid item xs={12} key={item.id}>
                  <Paper
                    elevation={1}
                    sx={{
                      padding: 2,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: 1,
                      borderColor: "#FF8900",
                      borderRadius: 2,
                    }}
                  >
                    <Box>
                      <Typography variant="h6">{item?.item_name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        ₹{Number(item?.item_price).toFixed(2)}
                      </Typography>
                    </Box>
                    <IconButton
                      onClick={() => {
                        removeFromCart(item);
                      }}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ marginTop: 4 }}>
              <TextField
                type="number"
                label="Number of Plates"
                value={numPlates}
                onChange={(e) => setNumPlates(parseInt(e.target.value, 10))}
                inputProps={{ min: 1 }}
                sx={{ width: 200, marginRight: 2 }}
              />
            </Box>
            <Box sx={{ marginTop: 4 }}>
              <Typography variant="h6" color="tomato">
                Apply Coupon Code
              </Typography>
              <TextField
                select
                label="Coupon Code"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                variant="outlined"
                sx={{ width: 200, marginTop: 2 }}
              >
                {availableCoupons.map((c) => (
                  <MenuItem key={c.code} value={c.code}>
                    {c.code}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            <Box
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{ alignSelf: "center" }}
                variant="h6"
                color="tomato"
              >
                Summary
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Typography>Total Plates:</Typography>
                <Typography>{numPlates}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                }}
              >
                <Typography>Total Cost:</Typography>
                <Typography> ₹{totalCost.toFixed(2)}</Typography>
              </Box>
            </Box>
            <Divider sx={{ marginY: 2 }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" color="tomato">
                Your Details
              </Typography>
              <Box sx={{ marginTop: 2 }}>
                <TextField
                  label="Name"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={!!errors.customerName}
                  helperText={errors.customerName}
                />
                <TextField
                  label="Mobile Number"
                  value={customerMobile}
                  onChange={(e) => setCustomerMobile(e.target.value)}
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={!!errors.customerMobile}
                  helperText={errors.customerMobile}
                />
              </Box>
              <Button
                variant="contained"
                color="warning"
                onClick={handleCheckout}
                sx={{
                  textTransform: "capitalize",
                }}
              >
                Send Enquiry
              </Button>
              {successMessage && (
                <Alert sx={{ marginTop: 2 }} severity="success">
                  {successMessage}
                </Alert>
              )}
            </Box>
          </Paper>
        )}
      </Container>
    </Layout>
  );
};

export default CartPage;
