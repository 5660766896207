// ItemDetailsModal.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";

const ItemDetailsModal = ({ open, onClose, item }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{item.item_name}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <img
            src={item.item_image}
            alt={item.item_name}
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
        <Typography variant="h6">Price: ₹{item.item_price}</Typography>
        <Typography variant="body1" mt={2}>
          {item.item_description}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemDetailsModal;
