import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Item from "./pages/Item";
import Items from "./pages/Items";
import CartPage from "./pages/cart/CartPage";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <div>
      <ParallaxProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/menu" element={<Items />} />
            <Route path="/item" element={<Item />} />
            <Route exact path="/cart" element={<CartPage />} />
          </Routes>
        </BrowserRouter>
      </ParallaxProvider>
    </div>
  );
}

export default App;
