import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "./../components/Layout/Layout";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loading from "../images/food_loading.gif";
import ChefHands from "../images/chef_hands.png";
/* 
interface item {
  id: Number;
  item_category: String;
  item_name: String;
  item_image: String;
} */
const Items = () => {
  const url = "https://us-central1-sricatersapi.cloudfunctions.net/app/items";
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [image, setImage] = useState("");

  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [items, setItems] = useState([]);

  /* useEffect(() => {
    if (selectedCategory) {
      
    }
  }, [selectedCategory]); */

  const goToItem = async (category) => {
    fetch(
      `https://www.sricaterers.com/getItemsByCategory.php?category_id=${category?.category_id}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Items:", data);
        setItems(data);
        navigate("/item", { state: { items: data } });
      })
      .catch((error) => console.error("Error fetching items:", error));
    // await getItemsOfCategory(category.category_id);
    // const passData = allData.filter((item) => item.item_category === category);
  };

  // const fetchInfo = async () => {
  //   const res = await axios.get(url, {
  //     headers: { "Content-Type": "application/json" },
  //   });
  //   setShowLoader(false);
  //   var unique = [];
  //   var distinct = [];
  //   for (let i = 0; i < res.data.length; i++) {
  //     if (!unique[res.data[i].item_category]) {
  //       distinct.push(res.data[i].item_category);
  //       unique[res.data[i].item_category] = 1;
  //     }
  //   }
  //   setAllData(res.data);
  //   setImage(res.data[0].item_image);
  //   console.log("DATA", distinct);
  //   return setData(distinct);
  // };

  // Fetch categories on component mount
  useEffect(() => {
    fetch("https://www.sricaterers.com/getCategories.php")
      .then((response) => response.json())
      .then((data) => {
        setShowLoader(false);
        setCategories(data);
        console.log("Categories:", data);
      })
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  return (
    <Layout>
      {showLoader ? (
        <div style={{ textAlign: "center" }}>
          <img src={Loading} alt="Loader" />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {categories.map((category, index) => (
            <Card
              key={index}
              sx={{
                maxWidth: "200px",
                display: "flex",
                m: 2,
                borderRadius: "10px",
                boxShadow: "1px 5px 10px rgb(0 0 0 / 47%)",
                transition: "transform 0.15s ease-in-out",
                "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
                cursor: "pointer",
              }}
              onClick={() => {
                goToItem(category);
              }}
            >
              <CardActionArea>
                <CardMedia
                  // sx={{ height: "100px" }}
                  component={"img"}
                  src={ChefHands}
                  alt={category}
                />
                <CardContent style={{ textAlign: "center" }}>
                  <Typography variant="h6" gutterBottom component={"div"}>
                    {category?.category_name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      )}
    </Layout>
  );
};

export default Items;
