import React, { useState, useEffect, useContext } from "react";
import Layout from "../components/Layout/Layout";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useLocation, useNavigate } from "react-router-dom";
import CartContext from "./cart/CartContext";
import "../styles/buttonStyle.css";
import { Height } from "../../node_modules/@mui/icons-material/index";
import ItemDetailsModal from "../components/ItemDetailsModal";
/* 
interface item {
  id: Number;
  item_category: String;
  item_name: String;
  item_image: String;
} */
const Item = () => {
  const [items, setItems] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  // Extract these functions from the CartContext
  const { addToCart, removeFromCart, cartItems } = useContext(CartContext);

  //Check whether the product is in the cart or not
  const isInCart = (product) => {
    return !!cartItems.find((item) => item.item_id === product.item_id);
  };

  useEffect(() => {
    //  console.log("Items", items);
    // fetchInfo();
  }, []);

  useEffect(() => {
    if (location && location?.state?.items) {
      console.log("DATAA", location.state.items);
      setItems(location.state.items);
    }
  }, [location]);

  return (
    <Layout>
      <Box mt={2} mb={2} display="flex" alignItems="center">
        <IconButton
          aria-label="open drawer"
          color="primary"
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon
            sx={{
              mr: 2,
              height: "30px",
              width: "30px",
            }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {items.map((menu, index) => (
          <Card
            key={index}
            sx={{
              maxWidth: "250px",
              display: "flex",
              m: 2,
              borderRadius: "10px",
              // boxShadow: "1px 5px 10px rgb(0 0 0 / 47%)",
              transition: "transform 0.15s ease-in-out",
              "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
              cursor: "pointer",
              // borderRadius: "50px",
              // background: "#e0e0e0",
              boxShadow: "2px 2px 2px #c1c1c1 ",
              // boxShadow: "10px 10px 10px #c1c1c1,-20px -20px 10px #ffffff",
            }}
            onClick={() => handleItemClick(menu)}
          >
            <CardActionArea>
              <CardMedia
                sx={{ height: "200px" }}
                component={"img"}
                src={menu.item_image}
                alt={menu.item_name}
              />
              <CardContent>
                <Typography variant="h6" gutterBottom component={"div"}>
                  {menu.item_name}
                </Typography>
                <Typography variant="body2">{menu?.item_category}</Typography>
                <div style={{ textAlign: "center", marginTop: 5 }}>
                  {isInCart(menu) && (
                    <Button
                      variant="text"
                      className="btn-5 custom-btn "
                      onClick={(event) => {
                        event.stopPropagation();
                        removeFromCart(menu);
                      }}
                    >
                      Remove from cart
                    </Button>
                  )}

                  {!isInCart(menu) && (
                    <Button
                      variant="text"
                      className="btn-5 custom-btn "
                      onClick={(event) => {
                        event.stopPropagation();
                        addToCart(menu);
                      }}
                    >
                      Add to Cart
                    </Button>
                    /*  <Button onClick={() => addToCart(menu)}>Add to Cart</Button> */
                  )}
                </div>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Box>
      {selectedItem && (
        <ItemDetailsModal
          open={isModalOpen}
          onClose={handleCloseModal}
          item={selectedItem}
        />
      )}
    </Layout>
  );
};

export default Item;
