import React from "react";
import { Box, Container, Typography, Link } from "@mui/material";

const StickyFooter = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mb: "0",
        backgroundColor: "#FFE1C0",
      }}
    >
      <Container maxWidth="sm" align={"center"}>
        <Typography variant="body2" color="text.secondary">
          {"© "}
          <Link color="inherit" href="#">
            Sricateres
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Box>
  );
};

export default StickyFooter;
