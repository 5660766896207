import Dosa from "../images/dosa.jpg";
import Chola from "../images/chhola.jpg";
import Idli from "../images/idli.jpg";
import MasalaDosa from "../images/masala.jpg";
import Paneer from "../images/paneer.jpg";
import Gujrati from "../images/gujrati.jpeg";

export const MenuList = [
  {
    name: "Masala Dosa",
    description:
      "Masala Dosa is a popular South Indian breakfast where a crispy crepe made of fermented rice and lentil batter is served with flavorful spiced potato curry. It is a wholesome meal in itself as it is served with potato masala",
    image: Dosa,
    price: 200,
  },
  {
    name: "Sambar",
    description:
      "Sambar! a South Indian lentil and mixed vegetable stew is the comfort food at its best. This traditional Sambar is hearty, super flavorful and loaded with vegetables, spices and herbs. Not to mention",
    image: Chola,
    price: 250,
  },
  {
    name: "Tapeswaram Madatha Kaja",
    description:
      "Tapeswaram Madatha Kaja/Thapeswaram Madatha Khaja is an intensely fried pastry steeped in sugar syrup that would make the final product juicy and crispy, giving the final hint of sweet flavor. ",
    image: Idli,
    price: 300,
  },
];
